import React from 'react';

import SEO from '~/components/seo';
import Collaborators from '~/components/Collaborators';

const CollaboratorsTemplate = props => (
  <>
    <SEO title="Collaborators.title" pageContext={props.pageContext} />
    <Collaborators />
  </>
);

export default CollaboratorsTemplate;
