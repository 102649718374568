import React from 'react';
import Hero from '~/components/Hero/index.tsx';
import { Section, ExternalLink } from '~/components/BaseElements';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import collaborators from './collaborators';
import partners from './partners';
import * as Styled from './styles';

import SurveyMonkeyLogo from '~/assets/images/collaborators/survey-monkey.png';
import CarnegieMelonDelphieGroupLogo from '~/assets/images/collaborators/carnegie-melon-delphie-group.png';
import CovidSymptomTrackerLogo from '~/assets/images/collaborators/covid-symptom-tracker.jpg';
import AlphaLogo from '~/assets/images/collaborators/alpha.png';

import FluLabLogo from '~/assets/images/partners/flulab.png';
import EndingPandemicsLogo from '~/assets/images/partners/ending-pandemics.png';

const Collaborators = () => {
  const { t } = useTranslation();

  const collaboratorsLogos = {
    SurveyMonkey: SurveyMonkeyLogo,
    CarnegieMelonDelphieGroup: CarnegieMelonDelphieGroupLogo,
    CovidSymptomTracker: CovidSymptomTrackerLogo,
    Alpha: AlphaLogo,
  };

  const partnersLogos = {
    FluLab: FluLabLogo,
    EndingPandemics: EndingPandemicsLogo,
  };

  return (
    <Section py={0}>
      <Hero isSubPage>{t('Collaborators.title')}</Hero>
      <Styled.Box py={8}>
        <Grid container justifyContent="center">
          <Grid item md={8}>
            <Styled.P>{t('Collaborators.p1')}</Styled.P>
          </Grid>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={5}
            item
            md={5}
          >
            {partners.map((partner, i) => (
              <Grid item key={partner.name}>
                <ExternalLink to={partner.link}>
                  <Styled.PartnerImg
                    src={partnersLogos[partner.logo]}
                    alt={`${partner.name} logo`}
                  />
                </ExternalLink>
              </Grid>
            ))}
          </Grid>

          <Grid item md={8}>
            <Styled.P>{t('Collaborators.p2')}</Styled.P>
          </Grid>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={5}
            item
            md={6}
          >
            {collaborators.map((collaborator, i) => (
              <Grid item key={collaborator.name}>
                <ExternalLink to={collaborator.link}>
                  <Styled.CollabImg
                    src={collaboratorsLogos[collaborator.logo]}
                    alt={`${collaborator.name} logo`}
                  />
                </ExternalLink>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Styled.Box>
    </Section>
  );
};

export default Collaborators;
