import React from 'react';
import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import Typography from '@material-ui/core/Typography';
import BoxUnstyled from '@material-ui/core/Box';

export const Box = styled(BoxUnstyled)`
  background-color: ${props => props.theme.palette.common.white};
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  ${breakpoint.sm`
    padding-left: inherit;
    padding-right: inherit;
  `}
`;

export const PartnerImg = styled.img`
  max-width: 80vw;
  max-height: 120px;
  margin-bottom: 70px;
`;

export const CollabImg = styled.img`
  max-width: 30vw;
  max-height: 70px;
`;

export const P = styled(({ variant = 'body1', ...restProps }) => {
  return <Typography variant={variant} {...restProps} />;
})`
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  text-align: center;
`;
